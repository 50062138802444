<template>
  <div class="billing">
    <Card>
      billing
    </Card>
  </div>
</template>

<script>
import Card from "@/components/Card";
import { mapGetters } from "vuex";

export default {
  components: {
    Card
  },
  computed: {
    ...mapGetters("teams", {
      role: "getRole"
    })
  },
  mounted() {
    if (this.role && this.role.toLowerCase() !== "admin") {
      this.$router.push({
        name: "Dashboard"
      });
    }
  },
  methods: {}
};
</script>

<style lang="scss">
.billing {
}
</style>
