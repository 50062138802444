<template>
  <div class="team">
    <div class="team__title">{{ $t("pages.settings.team.title") }}</div>
    <Card class="team__card">
      <div class="team__card-title">
        {{ $t("pages.settings.team.form.title") }}
      </div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form class="team__form" @submit.prevent="handleSubmit(onSave)">
          <div class="team__field" @click="showPhotoUploadModal = true">
            <label class="team__label" for="TeamImage">{{
              $t("pages.settings.team.form.avatar")
            }}</label>
            <!-- <inline-svg
              class="team__avatar-icon team__avatar"
              :src="require(`../../assets/icons/Avatar.svg`)"
              aria-label="Image"
              v-if="!currentTeam.avatar"
            ></inline-svg> -->
            <ReplacementLogo
              :name="name"
              class="team__avatar"
              v-if="!currentTeam.avatar"
            />
            <div
              class="team__avatar"
              v-else
              :style="{
                'background-image': 'url(' + currentTeam.avatar + ')'
              }"
            ></div>
          </div>

          <ValidationProvider
            class="team__field"
            name="Name"
            rules="required"
            v-slot="{ errors }"
          >
            <label class="team__label" for="Name">{{
              $t("pages.settings.team.form.name")
            }}</label>
            <input class="team__input" v-model="name" type="text" />
            <span class="team__error">{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider
            class="team__field"
            name="Location"
            rules="required"
            v-slot="{ errors }"
          >
            <label class="team__label" for="Location">{{
              $t("pages.settings.team.form.location")
            }}</label>
            <input class="team__input" v-model="location" type="text" />
            <span class="team__error">{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider
            class="team__field"
            name="BranchId"
            v-slot="{ errors }"
          >
            <label class="team__label" for="BranchId">{{
              $t("pages.settings.team.form.branchId")
            }}</label>
            <input class="team__input" v-model="branchId" type="text" />
            <span class="team__error">{{ errors[0] }}</span>
          </ValidationProvider>

          <div class="team__id-wrapper team__field">
            <div class="team__id-title team__label">
              {{ $t("pages.settings.team.form.lobbySpaceId") }}
            </div>
            <div class="team__id">{{ currentTeam.id }}</div>
          </div>

          <Button
            theme="secondary"
            class="team__button"
            type="submit"
            v-if="showSaveBtn"
          >
            {{ $t("pages.settings.team.form.saveBtn") }}
          </Button>
        </form>
      </ValidationObserver>
    </Card>

    <Card class="team__card">
      <div class="team__lobbyspace-templates-setting">
        <div class="team__lobbyspace-templates-setting-left">
          <div class="team__lobbyspace-templates-setting-icon-wrapper">
            <inline-svg
              class="team__lobbyspace-templates-setting-icon"
              :src="require('../../assets/icons/Templates.svg')"
              aria-label="icon"
            ></inline-svg>
          </div>
          <div class="team__lobbyspace-templates-setting-text">
            {{ $t("pages.settings.team.lobbySpaceTemplateAccess") }}
          </div>
        </div>
        <Toggle
          v-model="currentTeam.lobbySpaceTemplateAccess"
          @click.native="onUpdateLobbySpaceTemplateSetting"
        />
      </div>
    </Card>

    <!-- <Card class="team__card">
      <div class="team__lobbyspace-templates-setting">
        <div class="team__lobbyspace-templates-setting-left">
          <div class="team__lobbyspace-templates-setting-icon-wrapper">
            <inline-svg
              class="team__lobbyspace-templates-setting-icon"
              :src="require('../../assets/icons/Templates.svg')"
              aria-label="icon"
            ></inline-svg>
          </div>
          <div class="team__lobbyspace-templates-setting-text">
            {{ $t("pages.settings.team.defaultOrientation") }}
          </div>
        </div>
        <DropDown
          class="team__orientation"
          :value="orientation"
          @input="onOrientationChange($event)"
          :list="gridOptions"
          :direction="'top'"
          :no-border="true"
        >
          <template slot="selected">
            <span class="team__selected-orientation">{{
              $t(`dropdown.${orientation}`)
            }}</span>
          </template>
        </DropDown>
      </div>
    </Card> -->

    <Card class="team__card">
      <div class="team__card-title">
        {{ $t("pages.settings.team.users.title") }}
      </div>

      <TeamUser
        :user="user"
        v-for="(user, index) in currentTeam.users"
        :key="index"
        @role-set="onRoleSet"
        @delete="onDeleteUser(index)"
      >
      </TeamUser>

      <Button
        theme="secondary"
        class="team__invite-button"
        @click="showAddUser = true"
      >
        <inline-svg
          class="team__invite-icon"
          :src="require(`../../assets/icons/Plus.svg`)"
          aria-label="Image"
        ></inline-svg>
        {{ $t("pages.settings.team.users.inviteButton") }}
      </Button>

      <div class="team__invited-users">
        <div class="team__invited-users-title">
          {{ $t("pages.settings.team.invited.title") }}
        </div>
        <div v-if="invitedUsers.length > 0">
          <div
            class="team__invited-user"
            v-for="(invite, index) in invitedUsers"
            :key="index"
          >
            <div class="team__invited-user-data">
              <div class="team__invited-user-data-field">
                <div class="team__invited-user-data-email">
                  {{ invite.email }}
                </div>
                <div class="team__invited-user-data-role">
                  {{ $t(`dropdown.${invite.role}`) }}
                </div>
                <div class="team__invited-user-data-date">
                  {{ $t("pages.settings.team.invited.sent") }}
                  {{ new Date(invite.date).toLocaleDateString() }}
                </div>
              </div>

              <div
                class="team__invited-user-data-field team__invited-user-data-type"
              >
                <inline-svg
                  class="team__invited-user-data-ms-icon"
                  :src="require(`../../assets/icons/Microsoft_SSO.svg`)"
                  aria-label="Microsoft Logo"
                  v-if="invite.loginMethod === 'microsoft'"
                ></inline-svg>
              </div>

              <div
                class="team__invited-user-data-field team__invited-user-data-buttons"
              >
                <Button
                  theme="secondary"
                  class="team__invited-user-data-resend"
                  @click="onResendInvite(invite)"
                >
                  {{ $t("pages.settings.team.invited.resendBtn") }}
                </Button>

                <Button
                  class="team__invited-user-data-copy-link"
                  @click="copyLink(invite)"
                >
                  {{ $t("pages.settings.team.invited.copyLinkBtn") }}
                </Button>
              </div>
              <div class="team__invited-user-data-field">
                <inline-svg
                  @click="onDeleteInvite(invite)"
                  class="team__invited-user-data-delete"
                  :src="require('../../assets/icons/X.svg')"
                  aria-label="Delete Invite"
                ></inline-svg>
              </div>
            </div>
            <div class="team__divider"></div>
          </div>
        </div>
        <div class="team__invited-user" v-else>
          {{ $t("pages.settings.team.invited.noInvites") }}
        </div>
      </div>
    </Card>

    <TeamUserModal v-if="showAddUser" @close="onClose"></TeamUserModal>
    <PhotoUploadModal
      v-if="showPhotoUploadModal"
      @close="showPhotoUploadModal = false"
      avatar-type="team"
      :upload-type="uploadType"
    ></PhotoUploadModal>

    <Modal
      class="team__resend-success-modal"
      :heading="$t('pages.settings.team.success.titleResend')"
      :show-close-button="false"
      v-if="showResendSuccess"
      @close="showResendSuccess = false"
    >
      <template #modal-content>
        <div class="team__resend-success-content">
          {{ $t("pages.settings.team.success.content") }}
          <span>{{ email }}</span>
        </div>
        <Button
          class="team__resend-success-btn"
          @click="showResendSuccess = false"
        >
          {{ $t("pages.settings.team.success.doneBtn") }}
        </Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import Card from "@/components/Card";
import Button from "@/components/Button";
// import DropDown from "@/components/DropDown.vue";
import Modal from "@/components/Modal";
import Toggle from "@/components/Toggle";
import TeamUserModal from "@/components/TeamUserModal";
import PhotoUploadModal from "@/components/PhotoUploadModal";
import TeamUser from "@/components/TeamUser";
import ReplacementLogo from "@/components/ReplacementLogo";
import { mapActions, mapGetters } from "vuex";

extend("email", email);

// Override the default message.
extend("required", required);

export default {
  components: {
    Card,
    Button,
    // DropDown,
    Modal,
    Toggle,
    TeamUser,
    TeamUserModal,
    PhotoUploadModal,
    ReplacementLogo,
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    name: "",
    location: "",
    branchId: "",
    showAddUser: false,
    showPhotoUploadModal: false,
    showResendSuccess: false,
    email: "",
    gridOptions: ["orientation_0", "orientation_1"]
  }),
  computed: {
    ...mapGetters("teams", {
      currentTeam: "getCurrentTeam",
      role: "getRole"
    }),
    invitedUsers() {
      if (this.currentTeam.invitedUsers) {
        return Object.values(this.currentTeam.invitedUsers);
      }

      return [];
    },
    showSaveBtn() {
      return (
        this.name !== this.currentTeam.name ||
        this.location !== this.currentTeam.location ||
        this.branchId !== this.currentTeam.branchId
      );
    },
    uploadType() {
      if (this.currentTeam.avatar) {
        return "update";
      }

      return "upload";
    },
    orientation() {
      return this.currentTeam.slideOrientation
        ? this.currentTeam.slideOrientation
        : "orientation_0";
    }
  },
  watch: {
    currentTeam() {
      this.name = this.currentTeam.name;
      this.location = this.currentTeam.location;
      this.branchId = this.currentTeam.branchId;
    }
  },
  mounted() {
    this.name = this.currentTeam.name;
    this.location = this.currentTeam.location;
    this.branchId = this.currentTeam.branchId;

    if (this.role && this.role.toLowerCase() !== "admin") {
      this.$router.push({
        name: "Dashboard"
      });
    }
  },
  methods: {
    ...mapActions("teams", ["updateTeam", "deleteTeamFromUser"]),
    ...mapActions("notifications", ["addNotification"]),
    ...mapActions("invites", ["inviteUser", "deleteInvite"]),
    onRoleSet() {
      this.updateTeam(this.currentTeam);
    },
    onOrientationChange(orientation) {
      this.currentTeam.slideOrientation = orientation;
      this.updateTeam(this.currentTeam);
    },
    onSave() {
      this.updateTeam({
        ...this.currentTeam,
        name: this.name,
        location: this.location,
        branchId: this.branchId ? this.branchId : ""
      })
        .then(() => {
          this.addNotification({
            title: "team",
            type: "success",
            autoRemove: true
          });
        })
        .catch(() => {
          this.addNotification({
            title: "error",
            type: "error",
            autoRemove: true
          });
        });
    },
    onClose() {
      this.showAddUser = false;
    },
    onDeleteUser(id) {
      delete this.currentTeam.users[id];
      this.updateTeam(this.currentTeam);

      this.deleteTeamFromUser({
        userId: id,
        teamId: this.currentTeam.id
      })
        .then(() => {
          this.addNotification({
            title: "userDeleted",
            type: "success",
            autoRemove: true
          });
        })
        .catch(() => {
          this.addNotification({
            title: "error",
            type: "error",
            autoRemove: true
          });
        });
    },
    onDeleteInvite(invite) {
      let mail = "";
      if (invite.loginMethod === "email") {
        mail = invite.email;
      } else if (invite.loginMethod === "microsoft") {
        mail = invite.email + "#ext#@samlobbyspace.onmicrosoft.com";
      }

      let adjustedMail = mail.replace(/\.|#/g, "_");
      delete this.currentTeam.invitedUsers[adjustedMail];
      this.updateTeam(this.currentTeam);

      this.deleteInvite(invite.invitationId);
    },
    copyLink(invite) {
      const linkToCopy = `https://app2.lobbyspace.me/invite/${invite.invitationId}`;
      navigator.clipboard
        .writeText(linkToCopy)
        .then(() => {
          this.addNotification({
            title: "linkCopied",
            type: "success",
            autoRemove: true
          });
        })
        .catch(err => {
          console.error("Unable to copy link: " + err);
        });
    },
    onUpdateLobbySpaceTemplateSetting() {
      this.updateTeam(this.currentTeam);
    },
    onResendInvite(invite) {
      this.email = "";
      this.deleteInvite(invite.invitationId);

      let id = Math.random()
        .toString(36)
        .substr(2, 8);

      let invitation = {
        id: id,
        email: invite.email,
        role: invite.role,
        from: invite.invitedFrom,
        loginMethod: invite.loginMethod,
        team: {
          id: this.currentTeam.id,
          name: this.currentTeam.name
        }
      };

      let mail = "";
      if (invite.loginMethod === "email") {
        mail = invite.email;
      } else if (invite.loginMethod === "microsoft") {
        mail = invite.email + "#ext#@samlobbyspace.onmicrosoft.com";
      }

      let adjustedMail = mail.replace(/\.|#/g, "_");
      this.inviteUser(invitation).then(() => {
        this.currentTeam.invitedUsers[adjustedMail].date = new Date();
        this.currentTeam.invitedUsers[adjustedMail].invitationId = id;

        this.updateTeam(this.currentTeam);
        this.email = invite.email;
        this.showResendSuccess = true;
      });
    }
  }
};
</script>

<style lang="scss">
.team {
  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 1rem;
  }

  &__card {
    margin-bottom: 1rem;
  }

  &__card-title,
  &__invited-users-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0.5rem;
  }

  &__form,
  &__field {
    display: flex;
    flex-direction: column;
  }

  &__selected-orientation {
    @apply text-ls-gray-800 font-semibold text-xs mr-2;
  }

  &__divider {
    height: 1px;
    width: 100%;
    background-color: #ebebeb;
    margin: 8px 0;
  }

  &__avatar {
    width: 68px;
    height: 68px;
    border-radius: 15px;
    @apply bg-cover;
    cursor: pointer;
    font-size: 40px;
  }

  &__field {
    @apply mt-6;
  }

  &__input {
    @apply bg-ls-gray-100 rounded-lg px-2 py-3;
    font-size: 14px;
    line-height: 20px;
  }

  &__label,
  &__error {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }

  &__label {
    margin-bottom: 0.25rem;
  }

  &__error {
    @apply text-ls-red mt-1;
  }

  &__button {
    @apply mt-4;
  }

  &__lobbyspace-templates-setting {
    @apply flex justify-between items-center;

    &:not(:last-of-type) {
      @apply border-b border-ls-gray-200;
    }

    &-left {
      @apply flex items-center;
    }

    &-icon {
      &-wrapper {
        @apply p-3 rounded-full bg-ls-gray-300 mr-2;
      }
    }
  }

  &__invite-button {
    @apply mt-4;
  }

  &__invited-users {
    @apply mt-6;

    a {
      @apply text-ls-purple;
    }
  }

  &__invited-user-data {
    @apply flex w-full justify-between;

    &-field {
      @apply flex flex-col;

      &:nth-child(1) {
        width: 50%;
      }

      &:nth-child(2) {
        width: 5%;
        align-items: flex-end;
        justify-content: center;
      }

      &:nth-child(3) {
        width: 40%;
      }

      &:nth-child(4) {
        width: 5%;
        align-items: flex-end;
        justify-content: center;
      }
    }

    &-ms-icon {
      width: 24px;
      height: 24px;
    }

    &-buttons {
      @apply flex flex-row items-center justify-end;
    }

    &-email {
      @apply text-sm;
    }

    &-resend {
      @apply w-2/5 mr-2;
    }

    &-date,
    &-resend {
      @apply text-xs;
    }

    &-delete {
      @apply text-ls-gray-800 cursor-pointer;
      margin-top: 2px;

      path {
        @apply stroke-current;
      }
    }

    &-role {
      @apply text-xs text-ls-gray-800;
    }
  }

  &__invite-icon {
    margin-right: 0.5rem;
  }

  &__resend-success-modal {
    text-align: center;
  }

  &__resend-success-content {
    @apply mt-6 mb-4;

    span {
      @apply font-semibold;
    }
  }

  &__resend-success-btn {
    @apply w-full;
  }
}
</style>
