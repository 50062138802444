<template>
  <div class="settings">
    <SideBar class="settings__sidebar--desktop" :list="slideLinks"></SideBar>
    <SideBarMobile
      class="settings__sidebar--mobile"
      :list="slideLinks"
    ></SideBarMobile>
    <div class="settings__wrapper" v-if="role">
      <component class="settings__content" :is="componentName"></component>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import SideBarMobile from "@/components/SideBarMobile";
import Account from "./Account";
import Team from "./Team";
import LinkedTeams from "./LinkedTeams";
import Billing from "./Billing";
import ScreenTags from "./ScreenTags";
import { settingsAdmin, settingsEditor } from "@/schema/sideBar";
import { mapGetters } from "vuex";

export default {
  components: {
    SideBar,
    SideBarMobile,
    Account,
    Team,
    LinkedTeams,
    Billing,
    ScreenTags
  },
  computed: {
    ...mapGetters("teams", {
      role: "getRole"
    }),
    slideLinks() {
      if (this.role && this.role.toLowerCase() === "admin") {
        return settingsAdmin;
      }

      return settingsEditor;
    },
    componentName() {
      if (this.role === "editor") {
        let component = "admin";
        switch (this.$route.params.id) {
          case "account":
            component = this.$route.params.id;
            break;
          case "team":
            component = "account";
            break;
          case "linked-teams":
            component = "account";
            break;
          case "billing":
            component = "account";
            break;
          default:
            component = "account";
            break;
        }

        return component;
      }

      return this.$route.params.id;
    }
  }
};
</script>

<style lang="scss">
.settings {
  min-height: 100vh;

  &__sidebar--desktop {
    display: none;
  }

  &__wrapper {
    min-height: 100vh;
    width: 100%;
    padding: 4rem 1.5rem 1.5rem;
  }

  &__content {
    width: 100%;
  }
}

@media screen and (min-width: 900px) {
  .settings {
    &__sidebar--desktop {
      display: block;
    }

    &__sidebar--mobile {
      display: none;
    }

    &__wrapper {
      margin-left: 208px;
      width: auto;
      display: flex;
      justify-content: center;
      padding: 2.25rem 0 0;
    }

    &__content {
      width: 744px;
    }
  }
}
</style>
