<template>
  <div class="account">
    <div class="account__title">{{ $t("pages.settings.account.title") }}</div>
    <Card class="account__card" v-if="user">
      <div class="account__card-title">
        {{ $t("pages.settings.account.form.title") }}
      </div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form class="account__form" @submit.prevent="handleSubmit(onSave)">
          <div class="account__field" @click="showPhotoUploadModal = true">
            <label class="account__label" for="AccountImage">{{
              $t("pages.settings.account.form.avatar")
            }}</label>
            <inline-svg
              class="account__avatar-icon account__avatar"
              :src="require(`../../assets/icons/Avatar.svg`)"
              aria-label="Image"
              v-if="!user.avatar"
            ></inline-svg>

            <div
              class="account__avatar"
              v-else
              :style="{
                'background-image': 'url(' + user.avatar + ')'
              }"
            ></div>
          </div>

          <ValidationProvider
            class="account__field"
            name="Name"
            rules="required"
            v-slot="{ errors }"
          >
            <label class="account__label" for="Name">{{
              $t("pages.settings.account.form.name")
            }}</label>
            <input class="account__input" v-model="name" type="text" />
            <span class="account__error">{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider
            class="account__field"
            name="Email"
            rules="required|email"
            v-slot="{ errors }"
            v-if="!user.sso"
          >
            <label class="account__label" for="Email">{{
              $t("pages.settings.account.form.email")
            }}</label>
            <input class="account__input" v-model="email" type="email" />
            <span class="account__error">{{ errors[0] }}</span>
          </ValidationProvider>

          <div class="account__field">
            <label class="account__label">{{
              $t("pages.settings.account.form.language")
            }}</label>
            <DropDown
              class="account__language-dropdown"
              :list="languageList"
              v-model="language"
            >
              <template slot="selected">
                <div class="account__selected-language">
                  {{ $t(`dropdown.${language}`) }}
                </div>
              </template>
            </DropDown>
          </div>

          <Button
            theme="secondary"
            class="account__button"
            type="submit"
            v-if="showSaveBtn"
          >
            {{ $t("pages.settings.account.form.saveBtn") }}
          </Button>
        </form>
      </ValidationObserver>
    </Card>

    <Card class="account__card" v-if="!user.sso">
      <div class="account__card-title">
        {{ $t("pages.settings.account.passwordReset.title") }}
      </div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form
          class="account__form"
          @submit.prevent="handleSubmit(onPasswordReset)"
        >
          <ValidationProvider
            class="account__field"
            name="OldPassword"
            rules="required"
            v-slot="{ errors }"
          >
            <label class="account__label" for="OldPassword">{{
              $t("pages.settings.account.passwordReset.oldPassword")
            }}</label>
            <input
              class="account__input"
              v-model="oldPassword"
              type="password"
            />
            <span class="account__error">{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider
            class="account__field"
            name="NewPassword"
            rules="required"
            v-slot="{ errors }"
          >
            <label class="account__label" for="NewPassword">{{
              $t("pages.settings.account.passwordReset.newPassword")
            }}</label>

            <input
              class="account__input"
              v-model="newPassword"
              type="password"
            />
            <span class="account__error">{{ errors[0] }}</span>
          </ValidationProvider>

          <Button theme="secondary" class="account__button" type="submit">
            {{ $t("pages.settings.account.passwordReset.resetBtn") }}
          </Button>
        </form>
      </ValidationObserver>
    </Card>

    <Modal
      class="account__modal"
      v-if="showPasswordModal"
      @close="showPasswordModal = false"
    >
      <div class="account__modal-content" slot="modal-content">
        <ValidationObserver v-slot="{ handleSubmit }">
          <div class="account__modal-label">
            {{ $t("pages.settings.account.form.passwordModal.passwordHint") }}
          </div>
          <form
            class="account__form"
            @submit.prevent="handleSubmit(updateEmailAddress)"
          >
            <ValidationProvider
              class="account__field"
              name="OldPassword"
              rules="required"
              v-slot="{ errors }"
            >
              <label class="account__label" for="OldPassword">{{
                $t("pages.settings.account.form.passwordModal.passwordLabel")
              }}</label>
              <input
                class="account__input"
                v-model="password"
                type="password"
              />
              <span class="account__error">{{ errors[0] }}</span>
            </ValidationProvider>

            <Button theme="secondary" class="account__button" type="submit">
              {{ $t("pages.settings.account.form.passwordModal.saveBtn") }}
            </Button>
          </form>
        </ValidationObserver>
      </div>
    </Modal>

    <PhotoUploadModal
      v-if="showPhotoUploadModal"
      @close="showPhotoUploadModal = false"
      avatar-type="user"
      :upload-type="uploadType"
    ></PhotoUploadModal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import Card from "@/components/Card";
import Modal from "@/components/Modal";
import PhotoUploadModal from "@/components/PhotoUploadModal";
import DropDown from "@/components/DropDown";
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/Button";

extend("email", email);

// Override the default message.
extend("required", required);

export default {
  components: {
    Card,
    Modal,
    PhotoUploadModal,
    ValidationObserver,
    ValidationProvider,
    DropDown,
    Button
  },
  data: () => ({
    email: "",
    name: "",
    language: "",
    oldPassword: "",
    password: "",
    newPassword: "",
    showPasswordModal: false,
    showPhotoUploadModal: false,
    languageList: ["de", "en"]
  }),
  computed: {
    ...mapGetters("user", {
      user: "getUserProfile"
    }),
    showSaveBtn() {
      return (
        this.email !== this.user.email ||
        this.name !== this.user.name ||
        this.language !== this.user.language
      );
    },
    uploadType() {
      if (this.user.avatar) {
        return "update";
      }

      return "upload";
    }
  },
  watch: {
    user() {
      this.email = this.user.email;
      this.name = this.user.name;
      this.language = this.user.language;
    }
  },
  mounted() {
    if (this.user) {
      this.email = this.user.email;
      this.name = this.user.name;
      this.language = this.user.language;
    }
  },
  methods: {
    ...mapActions("user", ["updateEmail", "updateProfile", "updatePassword"]),
    ...mapActions("notifications", ["addNotification"]),
    onSave() {
      if (this.email !== this.user.email) {
        this.showPasswordModal = true;
      } else {
        this.updateProfile({ name: this.name, language: this.language })
          .then(() => {
            this.addNotification({
              title: "profile",
              type: "success",
              autoRemove: true
            });
          })
          .catch(() => {
            this.addNotification({
              title: "error",
              type: "error",
              autoRemove: true
            });
          });
        localStorage.setItem("language", this.language);
      }
    },
    updateEmailAddress() {
      this.updateEmail({
        newMail: this.email,
        oldPassword: this.password
      })
        .then(() => {
          this.addNotification({
            title: "email",
            type: "success",
            autoRemove: true
          });
        })
        .catch(() => {
          this.addNotification({
            title: "error",
            type: "error",
            autoRemove: true
          });
        });
      if (this.name !== this.user.name) {
        this.updateProfile({ name: this.name, language: this.language });
        localStorage.setItem("language", this.language);
      }
      this.showPasswordModal = false;
      this.password = "";
    },
    onPasswordReset() {
      this.updatePassword({
        oldPassword: this.oldPassword,
        newPassword: this.newPassword
      })
        .then(() => {
          this.addNotification({
            title: "password",
            type: "success",
            autoRemove: true
          });
        })
        .catch(() => {
          this.addNotification({
            title: "error",
            type: "error",
            autoRemove: true
          });
        });
    }
  }
};
</script>

<style lang="scss">
.account {
  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 1rem;
  }

  &__card {
    margin-bottom: 1rem;
  }

  &__card-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0.5rem;
  }

  &__form,
  &__field {
    display: flex;
    flex-direction: column;
  }

  &__avatar {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    @apply bg-cover;
    cursor: pointer;
  }

  &__modal-content {
    margin-top: 3rem;
  }

  &__field {
    @apply mt-6;
  }

  &__input {
    @apply bg-ls-gray-100 rounded-lg px-2 py-3;
    font-size: 14px;
    line-height: 20px;
  }

  &__label,
  &__error {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }

  &__label {
    margin-bottom: 0.25rem;
  }

  &__error {
    @apply text-ls-red mt-1;
  }

  &__language-dropdown {
    .dropdown__wrapper {
      width: 100%;
    }
  }

  &__selected-language {
    font-size: 14px;
    line-height: 20px;
  }

  &__button {
    @apply mt-4;
  }
}

@media screen and (min-width: 900px) {
  .account {
  }
}
</style>
