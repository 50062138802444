<template>
  <div class="root-content">
    <div class="root-content__header">
      <div
        class="root-content__logo"
        v-if="rootTeam.avatar"
        :style="{
          'background-image': 'url(' + rootTeam.avatar + ')'
        }"
      ></div>
      <ReplacementLogo
        :name="rootTeam.name"
        class="root-content__logo--unset"
        v-else
      />
      <div class="root-content__name">{{ rootTeam.name }}</div>
    </div>
    <div class="root-content__screens" v-if="subscribed">
      <div
        class="root-content__screen"
        v-for="(screen, index) in team.screens"
        :key="index"
      >
        <div class="root-content__screen-left">
          <inline-svg
            v-if="screen.orientation === 'landscape'"
            class="root-content__screen-icon"
            :src="require(`../assets/icons/Screen.svg`)"
            aria-label="Screen"
          ></inline-svg>
          <inline-svg
            v-if="screen.orientation === 'portrait'"
            class="root-content__screen-icon"
            :src="require(`../assets/icons/Portrait.svg`)"
            aria-label="Screen"
          ></inline-svg>
          <div class="root-content__screen-name">{{ screen.name }}</div>
        </div>
        <Toggle
          class="root-content__screen-toggle"
          v-model="subscribed[index].active"
          @click.native="onToggle(index)"
        ></Toggle>
      </div>
    </div>
    <div class="root-content__screens--empty" v-else>
      {{ $t("components.parentContent.empty") }}
    </div>
  </div>
</template>

<script>
import Toggle from "@/components/Toggle";
import ReplacementLogo from "@/components/ReplacementLogo";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Toggle,
    ReplacementLogo
  },
  props: {
    team: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters("teams", {
      rootTeam: "getRootTeam"
    }),
    subscribed() {
      if (!this.team.rootSubscriptions && this.team.screens) {
        return this.team.screens.map(screen => {
          return {
            name: screen.name,
            id: screen.id,
            active: false
          };
        });
      } else if (this.team.rootSubscriptions && this.team.screens) {
        if (this.team.rootSubscriptions.length === this.team.screens.length) {
          return this.team.rootSubscriptions;
        } else {
          return this.team.screens.map((screen, index) => {
            if (
              this.team.rootSubscriptions[index] &&
              screen.name === this.team.rootSubscriptions[index].name &&
              screen.id === this.team.rootSubscriptions[index].id
            ) {
              return this.team.rootSubscriptions[index];
            } else {
              return {
                name: screen.name,
                id: screen.id,
                active: false
              };
            }
          });
        }
      }

      return [];
    }
  },
  methods: {
    ...mapActions("teams", ["toggleScreen"]),
    onToggle() {
      this.toggleScreen({
        ...this.team,
        rootSubscriptions: this.subscribed
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.root-content {
  @apply border border-ls-gray-300 rounded;

  &__header {
    @apply bg-ls-gray-100 flex p-4 items-center;
  }

  &__screens {
    width: 100%;

    &--empty {
      @apply p-4;
    }
  }

  &__logo {
    @apply bg-cover w-6 h-6 mr-2 rounded-lg;

    &--unset {
      @apply w-6 h-6 mr-2 rounded-lg;
    }
  }

  &__screen {
    @apply px-4 py-2 flex items-center justify-between border-b border-ls-gray-200;

    &-left {
      display: flex;
      align-items: center;
    }

    &-name {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      margin-left: 8px;
    }

    &:last-child {
      border: none;
    }
  }
}
</style>
