<template>
  <div class="team-user">
    <div class="team-user__wrapper">
      <div class="team-user__information">
        <div class="team-user__name">
          {{ user.name }}
        </div>
        <div class="team-user__email">
          <span v-if="!user.sso">
            <span v-if="!isMS">{{ user.email }}</span>
            <span v-else
              >{{ $t("components.teamUser.loggedInWith") }} Microsoft</span
            >
          </span>
          <span v-else
            >{{ $t("components.teamUser.loggedInWith") }}{{ user.sso }}</span
          >
        </div>
      </div>
      <div class="team-user__edit">
        <DropDown
          v-if="user.id !== profile.user_id"
          class="team-user__role"
          :list="roles"
          v-model="user.role"
        >
          <template slot="selected">
            <span class="team-user__selected-option">{{
              $t(`dropdown.${user.role}`)
            }}</span>
          </template>
        </DropDown>
        <div class="team-user__selected-option" v-else>
          {{ $t(`dropdown.${user.role}`) }}
        </div>
        <div class="team-user__delete" @click="onDeleteUser">
          <inline-svg
            v-if="user.id !== profile.user_id"
            class="team-user__delete-icon"
            :src="require(`../assets/icons/X.svg`)"
            aria-label="Delete"
          ></inline-svg>
        </div>
      </div>
    </div>
    <div class="team-user__divider"></div>
  </div>
</template>

<script>
import DropDown from "@/components/DropDown";
import { mapGetters } from "vuex";

export default {
  components: {
    DropDown
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    roles: ["admin", "editor"]
  }),
  computed: {
    ...mapGetters("user", {
      profile: "getUserProfile"
    }),
    isMS() {
      return this.user.email.includes("samlobbyspace.onmicrosoft.com");
    }
  },
  watch: {
    "user.role"() {
      this.$emit("role-set");
    }
  },
  methods: {
    onDeleteUser() {
      this.$emit("delete");
    }
  }
};
</script>

<style lang="scss">
.team-user {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__name {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0.25rem;
  }
  &__email {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  }

  &__divider {
    height: 1px;
    width: 100%;
    background-color: #ebebeb;
    margin: 8px 0;
  }

  &__edit {
    display: flex;
    align-items: center;
  }

  &__role {
    position: relative;
    margin: 0 1rem;
  }

  .dropdown__selected-wrapper {
    border: none;
    padding: 0;
  }

  &__selected-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  &__selected-option {
    @apply text-ls-gray-800 font-semibold text-xs mr-2;
  }

  &__dropdown-wrapper {
    @apply border-ls-gray-200 border p-2 rounded-lg bg-white absolute shadow right-0;
    top: 24px;
    z-index: 5;
  }

  &__dropdown-option {
    @apply text-ls-gray-800 font-semibold text-xs cursor-pointer mb-2;
    min-width: 120px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
