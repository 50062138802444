<template>
  <div class="screen-tags">
    <div class="screen-tags__wrapper">
      <div class="screen-tags__header">
        <div class="screen-tags__title">
          {{ $t("pages.settings.screenTags.title") }}
        </div>
        <Button
          class="screen-tags__add-btn"
          @click="openModal('create')"
          theme="secondary"
          icon="Plus"
        >
          {{ $t("pages.settings.screenTags.addBtn") }}
        </Button>
      </div>
      <Card class="screen-tags__content">
        <p class="screen-tags__explanation">
          {{ $t("pages.settings.screenTags.explanation") }}
        </p>

        <div class="screen-tags__tag" v-for="(tag, index) in tags" :key="index">
          <div class="screen-tags__tag-left">
            <inline-svg
              class="screen-tags__tag-icon"
              :src="require(`../../assets/icons/Tag.svg`)"
              aria-label="Change"
              @click="openModal('change', index)"
            ></inline-svg>
            <div class="screen-tags__tag-title">
              {{ tag.name }}
            </div>
          </div>
          <div class="screen-tags__tag-actions">
            <inline-svg
              class="screen-tags__tag-action-icon"
              :src="require(`../../assets/icons/Adjust.svg`)"
              aria-label="Change"
              @click="openModal('change', index)"
            ></inline-svg>

            <!-- <inline-svg
              class="screen-tags__tag-action-icon"
              :src="require(`../../assets/icons/Trash.svg`)"
              aria-label="Delete"
              @click="openModal('delete', index)"
            ></inline-svg> -->
          </div>
        </div>
      </Card>
    </div>

    <Modal class="screen-tags__modal" @close="onClose" v-if="showModal">
      <div class="screen-tags__modal-content" slot="modal-content">
        <div class="screen-tags__modal-warning">
          <span v-if="modalAction === 'change'">{{
            $t("pages.settings.screenTags.changeWarning")
          }}</span>
          <span v-if="modalAction === 'delete'">{{
            $t("pages.settings.screenTags.deleteWarning")
          }}</span>
        </div>
        <form
          class="screen-tags__form"
          @submit.prevent="onCreateTag"
          v-if="modalAction === 'create'"
        >
          <div class="screen-tags__field">
            <label class="screen-tags__label" for="TagTitle">{{
              $t("pages.settings.screenTags.tagLabel")
            }}</label>
            <input
              name="TagTitle"
              class="screen-tags__input"
              v-model="newTag"
              type="text"
            />
          </div>

          <div class="screen-tags__loading-bar"></div>
          <Button
            class="screen-tags__create-btn"
            type="submit"
            :disabled="isDisabled"
          >
            {{ $t("pages.settings.screenTags.createBtn") }}
          </Button>
          <div class="screen-tags__error" v-if="feedback">{{ feedback }}</div>
        </form>

        <form
          class="screen-tags__form"
          @submit.prevent="onChangeTag"
          v-if="modalAction === 'change'"
        >
          <div class="screen-tags__field">
            <label class="screen-tags__label" for="TagTitle">{{
              $t("pages.settings.screenTags.tagLabel")
            }}</label>
            <input
              name="TagTitle"
              class="screen-tags__input"
              v-model="changedTagName"
              type="text"
            />
          </div>

          <div class="screen-tags__loading-bar"></div>
          <Button
            class="screen-tags__create-btn"
            type="submit"
            :disabled="isDisabled"
          >
            {{ $t("pages.settings.screenTags.changeBtn") }}
          </Button>
          <div class="screen-tags__error" v-if="feedback">{{ feedback }}</div>
        </form>

        <Button
          class="screen-tags__create-btn"
          @click="onDeleteTag"
          theme="warning"
          v-if="modalAction === 'delete'"
        >
          {{ $t("pages.settings.screenTags.deleteBtn") }}
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Button,
    Modal,
    Card
  },
  data: () => ({
    newTag: "",
    newTags: [],
    changedTagName: "",
    showModal: false,
    chosenTagIndex: null,
    feedback: "",
    modalAction: "change"
  }),
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam"
    }),
    tags() {
      let tags = {};

      let teamTags =
        this.team.tags && Object.keys(this.team.tags).length !== 0
          ? this.team.tags
          : {};

      return {
        ...tags,
        ...teamTags
      };
    },
    isDisabled() {
      const currentTags = Object.entries(this.tags).map(([id, value]) => ({
        id,
        name: value.name
      }));

      if (this.modalAction === "change") {
        return (
          this.changedTagName === "" ||
          currentTags.some(
            el =>
              el.name.toLowerCase().trim() ===
              this.changedTagName.toLowerCase().trim()
          )
        );
      } else if (this.modalAction === "create") {
        return (
          this.newTag === "" ||
          currentTags.some(
            el =>
              el.name.toLowerCase().trim() === this.newTag.toLowerCase().trim()
          )
        );
      }

      return false;
    }
  },
  methods: {
    ...mapActions("teams", ["updateTeam"]),
    openModal(action, index) {
      this.chosenTagIndex = index;
      if (action === "change") {
        this.changedTagName = this.tags[index].name;
      }
      this.modalAction = action;
      this.showModal = true;
    },
    onCreateTag() {
      let date = new Date();
      let id = String(date.getTime());
      let tagName = this.newTag.toLowerCase().trim();

      let teamTags =
        this.team.tags && Object.keys(this.team.tags).length !== 0
          ? this.team.tags
          : {};

      teamTags[id] = {
        name: tagName
      };
      this.team.tags = teamTags;

      this.updateTeam(this.team);
      this.onClose();
    },
    onChangeTag() {
      this.team.tags[this.chosenTagIndex].name = this.changedTagName;
      this.updateTeam(this.team);
      this.onClose();
    },
    onDeleteTag() {
      delete this.team.tags[this.chosenTagIndex];
      // this.team.slides.forEach(slide => {
      //   if (slide.tags) {
      //     slide.tags = slide.tags.filter(tag => tag.id !== this.chosenTagIndex);
      //   }
      // });

      this.updateTeam(this.team);
      this.onClose();
    },
    onClose() {
      this.changedTagName = "";
      this.newTag = "";
      this.showModal = false;
      this.feedback = "";
    }
  }
};
</script>

<style lang="scss">
.screen-tags {
  min-height: 100vh;
  padding: 24px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
  }

  &__explanation {
    margin-bottom: 16px;
  }

  &__tag {
    @apply py-2 px-3 rounded-lg border mb-2 flex items-center justify-between;

    &-left {
      @apply flex items-center;
    }

    &-icon {
      margin-right: 8px;
      @apply text-ls-purple;

      path {
        @apply fill-current;
      }
    }

    &-actions {
      display: none;
    }

    &-action-icon {
      cursor: pointer;

      &:last-child {
        margin-left: 16px;
      }
    }

    &:hover {
      .screen-tags__tag-actions {
        display: flex;
      }
    }
  }

  &__add-btn {
    align-self: flex-end;
  }
  &__form,
  &__field {
    display: flex;
    flex-direction: column;
  }
  &__field {
    @apply mt-6;
  }
  &__input {
    @apply bg-ls-gray-100 rounded-lg px-2 py-3;
    font-size: 14px;
    line-height: 20px;
  }
  &__label,
  &__error {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }
  &__error {
    @apply text-ls-red mt-2;
  }
  &__label {
    margin-bottom: 0.25rem;
  }
  &__create-btn {
    @apply mt-4 w-full;
  }

  &__screen {
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 900px) {
  .screen-tags {
    padding: 36px 64px;
    display: flex;
    justify-content: center;

    &__wrapper {
      width: 745px;
    }
  }
}
</style>
