<template>
  <div class="team-user-modal">
    <Modal
      v-if="showAddUser"
      @close="onClose"
      :heading="$t('pages.settings.team.form.modalTitle')"
    >
      <template slot="modal-content">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
            class="team-user-modal__form"
            @submit.prevent="handleSubmit(onAddNewUser)"
          >
            <div class="team-user-modal__field">
              <label class="team-user-modal__label">
                {{ $t("pages.settings.team.form.loginMethod") }}
              </label>
              <div class="team-user-modal__login-methods">
                <div
                  class="team-user-modal__login-method"
                  v-for="(method, index) in loginMethods"
                  :key="index"
                >
                  <input
                    type="radio"
                    :id="`login-method__${method}`"
                    :value="method"
                    v-model="loginMethod"
                  />
                  <label
                    class="team-user-modal__login-method-label"
                    :for="`login-method__${method}`"
                    >{{ method }}</label
                  >
                </div>
              </div>
            </div>
            <ValidationProvider
              class="team-user-modal__field"
              name="Email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <label class="team-user-modal__label" for="Email">{{
                $t("pages.settings.team.form.email")
              }}</label>
              <input
                class="team-user-modal__input"
                v-model="email"
                type="text"
              />
              <span class="team-user-modal__error">{{ errors[0] }}</span>
            </ValidationProvider>

            <div class="team-user-modal__field">
              <label class="team-user-modal__label">{{
                $t("pages.settings.team.form.role")
              }}</label>
              <DropDown
                class="team-user-modal__role-dropdown"
                :list="roles"
                v-model="role"
              >
                <template slot="selected">
                  <div class="team-user-modal__selected-role">
                    {{ $t(`dropdown.${role}`) }}
                  </div>
                </template>
              </DropDown>
            </div>

            <Button class="team-user-modal__button" type="submit">
              {{ $t("pages.settings.team.form.addBtn") }}
            </Button>
            <div class="team-user-modal__feedback">
              {{ feedback }}
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>

    <Modal
      class="team-user-modal__invite-success-modal"
      :heading="$t('pages.settings.team.success.title')"
      :show-close-button="false"
      v-if="showAddUserSuccess"
      @close="onClose"
    >
      <template #modal-content>
        <div class="team-user-modal__invite-success-content">
          {{ $t("pages.settings.team.success.content") }}
          <span>{{ email }}</span>
        </div>
        <Button class="team-user-modal__invite-success-btn" @click="onClose">
          {{ $t("pages.settings.team.success.doneBtn") }}
        </Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import DropDown from "@/components/DropDown";
import Modal from "@/components/Modal";
import Button from "@/components/Button";
import { mapActions, mapGetters } from "vuex";

extend("email", email);
extend("required", required);

export default {
  components: {
    Modal,
    Button,
    DropDown,
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    showAddUser: true,
    showAddUserSuccess: false,
    email: "",
    role: "admin",
    roles: ["admin", "editor"],
    loginMethods: ["email", "microsoft"],
    loginMethod: "email",
    feedback: ""
  }),
  computed: {
    ...mapGetters("user", {
      user: "getUserProfile"
    }),
    ...mapGetters("teams", {
      team: "getCurrentTeam"
    })
  },
  methods: {
    ...mapActions("invites", ["inviteUser"]),
    ...mapActions("teams", ["updateTeam"]),
    onClose() {
      this.$emit("close");
    },
    onSuccess() {
      this.showAddUser = false;
      this.showAddUserSuccess = true;
    },
    checkIfUserExists(adjustedMail) {
      return (
        !!this.team.invitedUsers &&
        Object.prototype.hasOwnProperty.call(
          this.team.invitedUsers,
          adjustedMail
        )
      );
    },
    onAddNewUser() {
      let id = Math.random()
        .toString(36)
        .substr(2, 8);

      let invite = {
        id: id,
        email: this.email,
        role: this.role,
        from: this.user.email,
        loginMethod: this.loginMethod,
        team: {
          id: this.team.id,
          name: this.team.name
        }
      };

      let mail = "";
      if (this.loginMethod === "email") {
        mail = this.email;
      } else if (this.loginMethod === "microsoft") {
        mail = this.email + "#ext#@samlobbyspace.onmicrosoft.com";
      }

      // the adjusted mail is there because firebase's realtime database doesn't allow . inside their object keys
      let adjustedMail = mail.replace(/\.|#/g, "_");

      if (this.checkIfUserExists(adjustedMail)) {
        this.feedback = this.$t("pages.settings.team.form.userAlreadyInvited");
      } else {
        this.feedback = "";
        this.inviteUser(invite).then(() => {
          if (this.team.invitedUsers) {
            this.team.invitedUsers[adjustedMail] = {
              email: this.email,
              role: this.role,
              invitedFrom: this.user.email,
              invitationId: id,
              date: new Date(),
              loginMethod: this.loginMethod
            };
          } else {
            let invitedUsers = {};
            invitedUsers[adjustedMail] = {
              email: this.email,
              role: this.role,
              invitedFrom: this.user.email,
              invitationId: id,
              date: new Date(),
              loginMethod: this.loginMethod
            };
            this.team.invitedUsers = invitedUsers;
          }

          this.updateTeam(this.team);
          this.onSuccess();
        });
      }
    }
  }
};
</script>

<style lang="scss">
.team-user-modal {
  &__form,
  &__field {
    display: flex;
    flex-direction: column;
  }

  &__field {
    @apply mt-6;
  }

  &__login-methods {
    display: flex;
  }

  &__login-method {
    @apply mr-4 flex items-center;
  }

  &__login-method-label {
    @apply ml-2 capitalize;
  }

  &__input {
    @apply bg-ls-gray-100 rounded-lg px-2 py-3;
    font-size: 14px;
    line-height: 20px;
  }

  &__label,
  &__error {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }

  &__selected-role {
    font-size: 14px;
    line-height: 20px;
  }

  &__role-dropdown {
    .dropdown__wrapper {
      width: 100%;
      top: 48px;
    }
  }

  &__label {
    margin-bottom: 0.25rem;
  }

  &__error,
  &__feedback {
    @apply text-ls-red mt-1 text-xs font-semibold;
  }

  &__button {
    @apply mt-4;
  }

  &__invite-success-modal {
    text-align: center;
  }

  &__invite-success-content {
    @apply mt-6 mb-4;

    span {
      @apply font-semibold;
    }
  }

  &__invite-success-btn {
    @apply w-full;
  }
}
</style>
